/* Position and sizing of burger button */
.bm-burger-button {
    /* NOTE: This must be fixed, since absolute positioning doesn't work as expected within a fixed div */
    position: fixed;
    /* High enough to be above the search bar, low enough to be below the search options menu. */
    z-index: 101 !important;

    width: 28px;
    height: 28px;
    right: 6px;
    top: 6px;
    opacity: 85%;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
/* NOTE: These are, for some reason, overriden by code, so must be !important to take effect */
.bm-cross-button {
    height: 28px !important;
    width: 28px !important;
    right: 6px !important;
    top: 6px !important;
    opacity: 85% !important;
    z-index: 0 !important;
}

.bm-cross-button:hover, .bm-burger-button:hover {
    transform: scale(1.05);
}
.bm-cross-button:active, .bm-burger-button:active {
    transform: scale(1.00);
}

.bm-cross-button:hover {
}

/* Color/shape of close button cross */
.bm-cross {
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
 */
.bm-menu-wrap {
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    position: fixed;
    right: 0;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
    text-transform: capitalize;
    border: none;
    background: none;
    font-size: 2em;
    color: #ffcba4;
    transition: transform ease 1s;
}

.bm-item:active {
}

.bm-item:hover {
    color: orange;
    cursor: pointer;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.liburry-settings-icon {
}

.liburry-settings-close-icon {
}
