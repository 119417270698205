.loadingBarContainer {
    position: absolute;
    top: 1px;
    left: 3px;
    height: 2px;
    transform-origin: top;
    transition: opacity .2s;
}

.loadingBarContainer, .loadingBar {
    border-top-left-radius:  2px;
    border-top-right-radius: 2px;
    z-index: 1001;
    transform: translateZ(0);
}

.loadingBar {
    position: absolute;
    height: 100%;
    transform-origin: left;
    transition: width .3s ease-in;
    will-change: width;
    opacity: 80%;
}

#chhaConfigBar {
    transition: width 1s ease-in;
    background: #aaaaaa;
}

#chhaDBDownloadBar {
    background: orange;
}

#chhaDBParsedBar {
    background: pink;
}

#chhaDBLoadBar {
    background: darkblue;
}

#chhaSearchBar {
    background: #00BC4C;
}
