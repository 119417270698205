.muh-error-container {
    width: 100%;
    padding-top: 3px;
}

.muh-error {
    max-width: 80%;
    margin: 0 auto;
    border: 1px solid black;
    border-radius: 8px;
    background: white;
}

.muh-error-header {
    font-style: italic;
    border-bottom: 1px solid black;
    background: white;
    padding: 0px 10px;
    text-align: center;
    border-radius: 8px 8px 0px 0px;
}

.muh-error-body {
    background: pink;
    border-bottom: 1px solid black;
}

.muh-error-type, .muh-error-message {
    padding: 0px 10px;
}
.muh-error-type {
    border-bottom: 1px solid black;
    padding: 0px 10px;
}

.muh-error-type-prefix, .muh-error-message-prefix {
    font-style: italic;
    display: inline-block;
    width: 20%;
    user-select: none;
}

.muh-error-type-content, .muh-error-message-content {
    padding-left: 5px;
    font-weight: bold;
    border-left: 1px solid black;
    display: inline;
}

.muh-howto {
    padding: 6px;
}

.ErrCollapsible {
    border: 1px solid black;
    transition: none;
}

.ErrCollapsible__trigger, .ErrCollapsible__triggerOpen {
    /* Without this, the clickable part of the trigger is very small. */
    display: block;
    background: #aaaaaa;
    font-weight: bold;

    padding-left: 8px;
    padding: 3px;
    font-family: monospace;
}

.ErrCollapsible__triggerOpen {
    border-bottom: 1px solid black;
}

.ErrCollapsible__trigger::before {
    content: "▶ ";
}

.ErrCollapsible__triggerOpen::before {
    content: "▼ ";
}

.ErrCollapsible__contentInner {
    /* react-composible doesn't seem to set overflow correctly with overflowWhenOpen, so manually set it here. */
    overflow-x: auto !important;
    overflow-y: hidden !important;
    padding-bottom: 10px;
    padding: 3px;
}
