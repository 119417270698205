.search-bar-container {
    position: relative;
}

.search-bar {
    top: 0px;
    position: fixed;
    width: 100%;
    z-index: 100;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, .1);
    padding-left: 2px;
    padding-right: 2px;
}

.search-bar input {
    background: white;
    width: 100%;
    border: 1px solid var(--light-border-outer);
    border-radius: 3px;

    box-sizing: border-box;
    padding-left: 36px;
    padding-bottom: 2px;
    padding-top: 2px;

    font-size: 20px;
    color: #3c3749;
    transition: all .3s ease;
}

.search-bar input::placeholder {
    color: #8885b7;
}

.search-bar input:focus {
    outline: none;
    transition: all .1s ease;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, .2);
}

@keyframes littleBlip {
    0% { transform: scale(1); }
    75% { transform: scale(1.07); }
    100% { transform: scale(1); }
}

/* each element div gets its own positioning */
.empty-mag-glass-div {
    opacity: 70%;
    position: absolute;
    z-index: 101;

    top: 7px;
    left: 8px;
    right: auto;

    width: 26px;
    height: 26px;
}

.empty-mag-glass {
    position: absolute;
    width: inherit;
    height: inherit;
    top: 0px;
    left: 0px;
}

.clickable-mag-glass-div {
    top: 6px;
    left: 8px;
    right: auto;

    animation: .8s cubic-bezier(.18,.89,.32,1.28) 1.3s 1 littleBlip;
}

.clickable-x-div {
    top: 6px;
    right: 68px;
    left: auto;
    animation: fade 2s linear;
    transition: opacity .1s;
}

.clickable-settings-sliders-div {
    top: 6px;
    right: 38px;
    left: auto;

}

/* then shares sizing/behavior */
.clickable-mag-glass-div, .clickable-settings-sliders-div, .clickable-x-div {
    position: absolute;

    z-index: 101;
    width: 28px;
    height: 28px;
    padding: 0px;
    border: 0px;
    opacity: 90%;
    cursor: pointer;
}

.clickable-mag-glass-div:hover, .clickable-settings-sliders-div:hover, .clickable-x-div:hover {
    transform: scale(1.05);
}

.clickable-mag-glass-div:active, .clickable-settings-sliders-div:active .clickable-x-div:active {
    transform: scale(1.00);
}

.clickable-mag-glass-button, .clickable-settings-sliders-button, .clickable-x-button {
    font-size: 0px;
    width: 100%;
    height: 100%;
    background: transparent;
    border: 0px;
}

.clickable-mag-glass, .clickable-settings-sliders, .clickable-x {
    position: absolute;
    width: inherit;
    height: inherit;
    top: 0px;
    left: 0px;
}

.clickable-x {
    padding: 6px;
}

.search-area-buffer {
    height: 41px;
}

.search-bar input {
    height: 40px;
}
