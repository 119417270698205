* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


/* TODO: set border box sizing by default */

:root {
    --light-border-outer: hsl(210,8%,75%);
    --medium-border-outer: hsl(210,8%,70%);
    --dark-border-outer: hsl(210,8%,60%);
    --light-border-inner: hsl(210,8%,85%);
    --medium-border-inner: hsl(210,8%,70%);
    --dark-border-inner: hsl(210,8%,60%);
}

input {
}

body {
/*    background: #d7eed7;*/
/*    background: #ADD8E6;*/
/*    background: #321321;*/
    background: lightblue;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    /*color: #24292f;*/
    color: #232629;
}

input, select, textarea, button {
    font-family: inherit;
}

mark {
    background: none;
    color: inherit;
    text-decoration: underline;
}


.ChhaTaigi {
/*    display: grid;*/
/*    grid-template-areas:*/
/*        "header"*/
/*        "searchbar"*/
/*        "options" */
/*        "results";*/
}

.liburry-main-area {
    transition: transform 1s ease;
}

.liburry-search-options-area {
    transform-origin: top;
}

.liburry-search-options-overlay {
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
    transition: opacity 0.3s ease 0s;
}

.liburry-default-display-area {
    padding: 20px 5% 0% 5%;
}

.liburry-default-display-area-app-selector {
    padding-top: 20px;
}
