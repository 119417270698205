* {
    box-sizing: border-box;
}

:root {
    --agnostic-title-border: #fff1e5;
    --agnostic-definition-border: #66605c;
    --font-size-definition: 14px;
}

.dbg-field-info, .dbg-area-info {
    font-size: 6px;
    display: inline-block;
}

.dbg-area-info {
    border: 1px solid orange;
    transform: translate(0px, -5px);
}

.dbg-field-info {
    border: 1px solid blue;
    transform: translate(0px, -3px);
}

.agnostic-container-container {
    border: 1px solid var(--light-border-outer);
    border-radius: 10px;
    margin-bottom: 3px;
    background: white;

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.agnostic-container-container:hover {
/*    background: #eeffee;*/
    border-left: 2px solid var(--medium-border-outer);
    padding-right: 0px;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, .2);

}

.agnostic-container-mainbox {
    flex-grow: 1;
}

.agnostic-container-debugbox {
    max-width: 10%;
    min-width: 5%;
    border-right: 1px solid black;
    align-items: center;
    z-index: 20;
}

.agnostic-container-rightbox {
    padding: 2px 5px 2px 2px;
    max-width: 15%;
    border-left: 1px solid var(--light-border-inner);
}

.agnostic-container-title, .agnostic-container-title_other {
    font-weight: 700;
}

.agnostic-container-titlebox {
    display: inline-flex;
    width: 100%;
    padding: 3px 3px 2px 5px;
}

.agnostic-container-title {
    display: inline-flex;
    gap: 1px;
/*    display: inline-flex;*/
/*    flex-wrap: wrap;*/
/*    gap: 6px;*/
}

.agnostic-container-title div {
    display: inline-block;
    border: 1px solid var(--dark-border-inner);
    border-radius: 10px;
    padding: 1px 5px 3px 5px;
}

.agnostic-container-title_other {
    display: inline-block;
/*    line-height: 1;*/
    padding-bottom: 4px;
    padding-left: 4px;
}

.agnostic-container-title_other > div {
    display: inline-block;
    font-size: 10px;
    color: #333333;
}

.agnostic-container-title_other > div::after {
    content: ")";
}

.agnostic-container-title_other > div::before {
    content: "(";
}

.agnostic-container-title_prefix {
    display: inline-block;
}

.agnostic-container-title_alternate {
    margin-left: auto;
    padding: 1px 2px 0px 0px;
    display: flex;
    flex-direction: column;
    text-align: right;
}

.agnostic-container-title_alternate > div {
    font-size: 8px;
    color: #777777;
}

.agnostic-container-title_alternate > div::before {
    content: "(";
}
.agnostic-container-title_alternate > div::after {
    content: ")";
}


.agnostic-container-dbname {
    font-size: 8px;
}

.agnostic-container-main_other {
    border-top: 1px solid var(--light-border-inner);
    padding: 0px 2px 5px 5px;
}

.agnostic-container-definition {
    text-indent: 10px;
    font-size: var(--font-size-definition);
}

.agnostic-container-definition > div {
    border-bottom: 1px solid var(--light-border-inner);
    display: block;
    min-width: 40%;
    padding-left: 7px;
    padding-right: 3px;
    padding-bottom: 1px;
/*    line-height: 1.2;*/
}


/* TODO: XXX:  instead of > div, create named sub-elements per area */

.agnostic-container-definition > div:first-of-type {
    border-top: 1px solid var(--light-border-inner);
}

.agnostic-container-definition > div:last-of-type {
    border-bottom: 0px;
}

.agnostic-container-long_definitions > div {
    margin-left: 10px;
    border: 1px solid var(--dark-border-inner);
    border-radius: 10px;
    padding: 4px;
/*    line-height: 1.2;*/
    display: block;
    font-size: var(--font-size-definition);
}

.agnostic-container-examples {
    display: flex;
    flex-direction: column;
}

.agnostic-container-examples > div {
    display: inline-block;
    margin-bottom: 1px;
    margin-left: 10px;
    padding-left: 4px;
    border: 1px solid var(--dark-border-inner);
    border-radius: 10px;
    font-size: var(--font-size-definition);
}

.agnostic-container-examples > div:last-of-type {
    margin-bottom: 0px;
}

.agnostic-container-matched_examples {
    padding-top: 3px;
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-definition);
}

.agnostic-matched-group-container {
    padding-top: 3px;
    display: flex;
    margin-bottom: 1px;
}

.agnostic-matched-group-prefix {
    display: inline-block;
    vertical-align: top;
    padding-right: 3px;
    padding-left: 2px;
}

.agnostic-matched-group {
    display: inline-block;
    border: 1px solid var(--light-border-inner);
    border-radius: 4px;
}

.agnostic-matched-group > div {
    padding: 1px;
    padding-left: 4px;
    padding-right: 4px;
    border: 1px solid var(--light-border-inner);
    display: block;
    border-radius: 3px;
    margin-bottom: 0px;
}

/* TODO: add related_words and related_words_pronunciation */

.area-title-long_definitions {
}

.area-title-examples {
}

.area-title-matched_examples {
}

.area-title-related_words {
}

.area-title-long_definitions, .area-title-examples, .area-title-matched_examples, .area-title-related_words {
    font-size: 10px;
    color: #222222;
}
